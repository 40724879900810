import { create } from "zustand";

const useBrandDetailStore = create((set) => ({
	brandDetail: null, // Initial state should be an empty array or your default state
	brandDetailValidating: true,
	setBrandDetail: (newBrandDetail) => set({ brandDetail: newBrandDetail }),
	setBrandDetailValidating: (newBrandDetailValidating) => set({ brandDetailValidating: newBrandDetailValidating }),
}));

export default useBrandDetailStore;

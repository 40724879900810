import useSWR from "swr";
import useBrandStore from "../stores/brand/brand.store";
import { brandFetcher } from "../fetcher/brand.fetcher";
import swrOptions from "../../utils/swr.config";
import { useEffect } from "react";

export const useBrandData = () => {
	const { setBrand, brand, setBrandValidating } = useBrandStore((state) => ({
		setBrand: state.setBrand,
		setBrandValidating: state.setBrandValidating,
		brand: state.brand,
	}));

	const {
		data: brandData,
		error: brandDataError,
		isValidating: brandDataValidating,
	} = useSWR(brand === null ? "/api/v1/brand/info" : null, brandFetcher, swrOptions);

	useEffect(() => {
		if (brandDataValidating) {
			setBrandValidating(true);
		} else if (!brandDataValidating && brandData && !brandDataError && brand === null) {
			setBrandValidating(false);
			setBrand(brandData);
		} else {
			setBrandValidating(false);
		}
	}, [brandData, brandDataError, brand, setBrand, setBrandValidating, brandDataValidating]);
};

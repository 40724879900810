import useSWR from "swr";
import useAccountStore from "../stores/account/account.store";
import { accountFetcher } from "../fetcher/account.fetcher";
import swrOptions from "../../utils/swr.config";
import { useEffect } from "react";

export const useAccountData = () => {
	const { setAccount, account, setAccountValidating } = useAccountStore((state) => ({
		setAccount: state.setAccount,
		setAccountValidating: state.setAccountValidating,
		account: state.account,
	}));

	const {
		data: accountData,
		error: accountDataError,
		isValidating: accountDataValidating,
	} = useSWR(account === null ? "/api/v1/account/me" : null, accountFetcher, swrOptions);

	useEffect(() => {
		if (accountDataValidating) {
			setAccountValidating(true);
		} else if (!accountDataValidating && accountData && !accountDataError && account === null) {
			setAccountValidating(false);
			setAccount(accountData);
		} else {
			setAccountValidating(false);
		}
	}, [accountData, accountDataError, account, setAccount, setAccountValidating, accountDataValidating]);
};

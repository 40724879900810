import useSWR from "swr";
import useAllMomentsStore from "../stores/moment/all-moments.store";
import { momentFetcher } from "../fetcher/moment.fetcher";
import swrOptions from "../../utils/swr.config";
import { useEffect } from "react";

export const useAllMomentsData = () => {
	const { setAllMoments, allMoments } = useAllMomentsStore((state) => ({
		setAllMoments: state.setAllMoments,
		allMoments: state.allMoments,
	}));

	const { data: allMomentsData, error: allMomentsDataError } = useSWR(
		allMoments === null ? "/api/v1/moment/all" : null,
		momentFetcher,
		swrOptions,
	);

	useEffect(() => {
		if (allMomentsData && !allMomentsDataError && allMoments === null) {
			setAllMoments(allMomentsData);
		}
	}, [allMomentsData, allMomentsDataError, allMoments, setAllMoments]);
};

import { create } from "zustand";

const useBrandStore = create((set) => ({
	brand: null, // Initial state should be an empty array or your default state
	brandValidating: true, // Initial state should be an empty array or your default state
	setBrand: (newBrand) => set({ brand: newBrand }),
	setBrandValidating: (newBrandValidating) => set({ brandValidating: newBrandValidating }),
}));

export default useBrandStore;

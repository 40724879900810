import useSWR from "swr";
import useBrandDetailStore from "../stores/brand/brand-detail.store";
import { brandFetcher } from "../fetcher/brand.fetcher";
import swrOptions from "../../utils/swr.config";
import { useEffect } from "react";

export const useBrandDetailData = () => {
	const { setBrandDetail, brandDetail, setBrandDetailValidating } = useBrandDetailStore((state) => ({
		setBrandDetail: state.setBrandDetail,
		setBrandDetailValidating: state.setBrandDetailValidating,
		brandDetail: state.brandDetail,
	}));

	const {
		data: brandDetailData,
		error: brandDetailDataError,
		isValidating: brandDetailDataValidating,
	} = useSWR(brandDetail === null ? "/api/v1/brand/detail" : null, brandFetcher, swrOptions);

	useEffect(() => {
		if (brandDetailDataValidating) {
			setBrandDetailValidating(true);
		} else if (!brandDetailDataValidating && brandDetailData && !brandDetailDataError && brandDetail === null) {
			setBrandDetailValidating(false);
			setBrandDetail(brandDetailData);
		} else {
			setBrandDetailValidating(false);
		}
	}, [
		brandDetailData,
		brandDetailDataError,
		brandDetail,
		setBrandDetail,
		setBrandDetailValidating,
		brandDetailDataValidating,
	]);
};

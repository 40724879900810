import React from "react";
import { useBrandData } from "../hooks/useBrandData";
import { useBrandDetailData } from "../hooks/useBrandDetailData";
import { useAllMomentsData } from "../hooks/useAllMomentsData";
import { useAccountData } from "../hooks/useAccountData";

const DataProvider = ({ children }) => {
	useBrandData();
	useBrandDetailData();
	useAllMomentsData();
	useAccountData();

	// Render children components
	return children;
};

export default DataProvider;
